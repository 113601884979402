import React from "react";
import PropTypes from "prop-types";
import moment from 'moment';

import '../styles/components/cardBlog.scss';
import '../styles/pages/blog.scss';

const CardBlog = ({ date, img, title, text = '', href }) => {
  return (
    <div className="CardBlog-Inner">
      <div className="CardBlog-Content">
        {img ? (
          <div className="CardBlog-Image">
            <a href={href}>
              <img loading="lazy" src={img} alt={title} />
            </a>
          </div>
        ) : null}

        <div className="CardBlog-Date">
          {moment(date, "YYYY-MM-DD").format("DD.MM.YYYY")}
        </div>

        <div className="CardBlog-Title">
          <a href={href} dangerouslySetInnerHTML={{ __html: title }} />
        </div>

        <div className="CardBlog-Text" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
};

CardBlog.propTypes = {
  date: PropTypes.string,
  href: PropTypes.string,
  img: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default CardBlog;
