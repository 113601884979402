import { graphql } from "gatsby";
import React from "react";

import SEO from "../components/seo";
import enMessages from '../i18n/blog/en.js'
import ruMessages from '../i18n/blog/ru.js'
import Layout from "../layout/Layout";

import '../styles/pages/blog.scss'

import CardBlog from "../components/CardBlog";
import SocialsBlog from "../components/SocialsBlog";

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const Blog = ({ data, pageContext }) => {
  const blogItems = React.useMemo(() => {
    return data.allSitePage.edges.map((item) => {
      return {
        date: item.node.context.date,
        href: item.node.path,
        img: item.node.context.pathImg,
        text: item.node.context.description,
        title: item.node.context.title,
      };
    });
  }, [data]);

  let listPage = [];
  let start = 1;
  while (start <= pageContext.numPages) {
    listPage.push(start++);
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        description={messages[pageContext.locale].seo.description}
        title={messages[pageContext.locale].seo.title}
      />

      <div className="container">
        <div className='Blog-Container'>
          <div className='Main-Column'>
            <div className='Blog-Items'>
              {blogItems.map((item, index) => (
                <CardBlog key={index} {...item} enabledBtn={false} className='Blog-Item' target='_self' />
              ))}
            </div>
          </div>

          <div className='Right-Column'>
            <SocialsBlog title={messages[pageContext.locale].socialTitle}/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query NewsCardsBlogQuery ($skip: Int!, $limit: Int!, $locale: String!) {
    allSitePage(sort: {fields: context___date, order: DESC}, limit: $limit, skip: $skip, filter: {path: {regex: "/blog/"}, context: {locale: {eq: $locale}, description: {ne: null}}}) {
      edges {
        node {
          path
          context {
            title
            description
            pathImg
            locale
            date
          }
        }
      }
    }
  }
`;

export default Blog;
